import React from "react";
import {useTranslation} from "react-i18next";
import {ListBulletIcon, PlusIcon} from "@heroicons/react/24/solid";
import {useHistory} from "react-router-dom";
import BookingLoginAction from "store/bookingLogin/BookingLoginAction";
import SessionAction from "store/session/SessionAction";
import HallAction from "store/hall/HallAction";
import {DEFAULT_HALL_ID} from "helpers/config";
import {ThunkAction} from "redux-thunk";
import BookingAction from "store/booking/BookingAction";
import useNavigation, {RouteName} from "utils/hooks/useNavigation";
import {ArrowRightStartOnRectangleIcon} from "@heroicons/react/24/outline";
import {useDispatch} from "useDispatch";
import clsx from "clsx";

interface SellerBreadcrumbProps {
}

const SellerBreadcrumb = (props: SellerBreadcrumbProps) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const {historyPush} = useNavigation();

    const navigationSteps = [
        {id: 1, label: t("Reservations list"), icon: "list", routeName: "/booking/seller/reservations", color: null},
        {id: 3, label: t("New reservation"), icon: "add", routeName: "/booking/seller/new-reservation", color: "green"},
    ];

    const historyPushHandler = (routeName: string) => {
        return history.push(routeName);
    }

    const logoutHandler = () => {
        dispatch(BookingLoginAction.fetchReservationsLogout())
            .then(r => {
                dispatch(SessionAction.loginVisitor());
                dispatch(HallAction.fetchHall(DEFAULT_HALL_ID)).then(r => {});
                dispatch<ThunkAction<any, any, any, any>>(BookingAction.fetchActivities());
            })
            .finally(() => {
                historyPush(RouteName.Main);
            });
    };

    return (
        <div className={"SellerBreadcrumb shadow-lg z-10 flex flex-col content-start w-full"}>
            <div className={"flex items-center overflow-hidden w-auto"}>
                <nav className={"max-w-full xl:max-w-7xl mx-auto px-4 xl:px-0 justify-between flex border-b border-gray-200 w-full bg-white overflow-x-auto scrollbar-x-hidden relative"}>
                    <ol className="flex flex-row">
                        {navigationSteps.map((step, index) => (
                            <li
                                key={index}
                                className={clsx(
                                    "flex items-center border-r pl-4 pr-4 first:pl-0 py-2 h-[44px] cursor-pointer text-sm leading-4 font-medium ",
                                    step.color == "green" ? "text-green-500 hover:text-green-600" : "text-black hover:text-arbo-orange",
                                )}
                                onClick={() => historyPushHandler(step.routeName)}
                            >
                                {step.icon == "list" && <ListBulletIcon className={"w-5 h-5 flex-none sm:mr-2"} />}
                                {step.icon == "add" && <PlusIcon className={"w-5 h-5 flex-none sm:mr-2"} />}
                                <span className={"hidden sm:inline"}>{step.label}</span>
                            </li>
                            ))}
                    </ol>
                    <ol className="flex flex-row">
                        <li
                            className="flex items-center pl-4 pr-0  py-2 h-[44px] cursor-pointer text-sm leading-4 font-medium text-red-500 hover:text-arbo-orange"
                            onClick={() => logoutHandler()}
                        >
                            <ArrowRightStartOnRectangleIcon className={"w-5 h-5 sm:mr-2 flex-none"} />
                            <span className={"hidden sm:inline"}>{t("Logout")}</span>
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    );
};

export default SellerBreadcrumb;